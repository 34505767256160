import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@400&display=swap'); */

:root {
  --primaryColor: #4CA499;
  --secondaryColor: #012626;
  --mainWhite: #fff;
  --offWhite: #F2F2F2;
  --mainBlack: #0D0D0D;
  --mainGrey: #A6A6A6;
  --darkGrey: #595959;
  --mainTransition: all 0.3s ease-in-out;
  --mainSpacing: 4px;
  --defaultFontSize: 1.2rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  scroll-behavior: smooth;
}
h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  h6 {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  p {
    margin: 16px 0px 32px;
    line-height: 1.625;
  }

.btn-white,
.btn-primary {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  font-weight: 800;
  border: 2px solid var(--mainWhite);
  padding: 0.9rem 1.6rem;
  display: inline-block;
  transition: var(--mainTransition);
  cursor: pointer;
  background: var(--mainWhite);
  z-index: 0;
  pointer-events: all; 
}
.btn-white:hover {
  background: var(--mainWhite);
  color: var(--primaryColor);
}
.btn-primary {
  background: var(--primaryColor);
  color: var(--mainWhite);
  border: 2px solid var(--primaryColor);
}
.btn-primary:hover {
  background: transparent;
}

.scrollToTarget {
  height: 0;
  position: relative;
  top: -80px; 
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

ul{
  margin-bottom: 32px;
  margin-top: 0;
    & li {
        margin-left: 2.5rem;
        list-style-type: square;
        list-style: outside;
        line-height: 1.8;
    }
	& li:first-child {
		margin-top: 0;
		padding: 0px;
	}
}
table {
    padding: 0;
    border-collapse: collapse;
  }

  table th {
    background-color: #fff;
  }

  table tr {
    border-top: 1px solid #cccccc;
    margin: 0;
    padding: 0;
  }

  table tr th {
    font-weight: bold;
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }

  table tr td {
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }

  table tr th :first-child,
  table tr td :first-child {
    margin-top: 0;
  }

  table tr th :last-child,
  table tr td :last-child {
    margin-bottom: 0;
  }

  table tr:nth-child(odd) {
    background-color: #f8f8f8 !important;
  }

  table tr:nth-child(even) {
    background-color: #fff;
  }
a {
  color: var(--primaryColor);
  font-weight: bold;
  text-decoration: none;
}
`;

export default GlobalStyle;
